import { useCallback, useMemo } from "react";
import ORM, { BaseModel } from "./orm";
import { usePipelineNodes } from "@stores/data.store";
import { ColumnFormat, ColumnRef } from "./shared";
import { useQuery } from "react-query";
import ApiService, { JobEnqueueResponse, ListRecordsResponse, SingleRecordResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";


export interface Filter {
    column_ref: ColumnRef;
    comparator: string;
    value?: string|string[];
    column_value?: ColumnRef;
}

export interface FilterConfig {
    filters: Filter[];
    logic_gate: string;
    custom_sql?: string;
}

export interface ReportColumnPath {
    label: string;
    object_type: string;
    object_id: string;
}

export interface NodeFieldJoinPath {
    label: string;
    object_type: string;
    object_id: string;
}

export interface DataWhitelistEntry {
    pipeline_node_id: string;
    field_id: string;
    value: string|string[];
    comparator: string;
}

export interface DataWhitelist {
    entries: DataWhitelistEntry[];
    logic_gate: string;
    custom_sql?: string;
}


export interface ReportColumn {
    id: string;
    label: string;
    path: ReportColumnPath[];
    column_name: string;
    column_selector: string;
    column_id?: string;
    datatype?: string;
    function?: string;
}

export interface ReportMetric {
    id: string;
    name: string
    aggregator?: string;
    column: ReportColumn;
    custom_sql?: string;
    window_arguments?: any[];
    window_function?: string;
    format?: ColumnFormat;

}

export interface ColumnSort {
    column_id: string;
    direction: 'DESC' | 'ASC';

}

export interface ColumnStats {
    key: string
    distinct_values: number;
    empty_values: number;
    uniqueness: number;
    density: number;
    samples: any[];
}


export interface PipelineNodeShape {
    total_records: number;
    columns: ColumnStats[];
    updated_at?: Date;
}

export interface PipelineNodeJoinPath {
    label: string;
    object_type: string;
    object_id: string;
}

export interface PipelineNodeMapOption {
    id: string;
    source_node_id?: string;
    attribute_key?: string;
    attribute_id?: string;
    combination_rule?: string;
    sub_options?: PipelineNodeMapOption[];
    hardcoded_value?: string;
    concat_joiner?: string;
    join_path?: PipelineNodeJoinPath[];
}


export enum CellAction {
    NAVIGATE_TO_NODE = 'NAVIGATE_TO_NODE',
    REFER_TO = 'REFER_TO'
  }

export interface PipelineNodeCellAction {
    label: string;
    action: CellAction;
    config: any;
}

export interface PipelineNodeField {
    id: string;
    type: string;
    label: string;
    name: string;
    description: string;
    part_of_composite_key: boolean;
    taxonomic_id: string;
    transformer?: string;
    transformer_arguments?: string[];
    formatter?: string;
    map_options: PipelineNodeMapOption[];
    relationship_id?: string;
    show_column?: boolean;

    // PRIORITIZE, SUM, MAX, MIN for how we handle when there are multiple values and we're grouping
    merge_behavior?: string;

    custom_sql?: string;
    advanced_mode?: boolean;
    cell_actions: PipelineNodeCellAction[];
}

export interface PipelineNodeDimension {
    pipeline_node_id: string;
    field_id: string;
    name: string;
    formatter?: string;
    include_all?: boolean;
}

export interface PipelineNodeCalculation {
    name: string;
    formula: string;
    formatter?: string;
}

export interface PipelineNodeMeasureJoinTree {
    downstream: PipelineNodeMeasureJoinTree[];
    node_id: string;
    relationship_id?: string;
}

export interface PipelineNodeMeasure {
    pipeline_node_id: string;
    field_id: string;
    name: string;
    join_tree: PipelineNodeMeasureJoinTree|null;
    aggregator: string;
    formatter?: string;
    data_whitelist?: DataWhitelist
}

export interface PipelineNodeExtraReportColumn {
    pipeline_node_id: string;
    field_id: string;
    column_label: string;
}

export interface PipelineNode extends BaseModel {
    name: string;
    label: string;
    description: string;
    table_name: string;
    flat_file?: boolean;
    image_url?: string;
    upstream_node_ids: string[];
    fields: PipelineNodeField[];
    node_type: string;
    filters?: FilterConfig;
    data_whitelist?: DataWhitelist;
    default_sort?: ColumnSort[];
    shape?: PipelineNodeShape;
    combine_logic_gate?: string;
    combine_required_match_count?: number;
    composite_key?: string[];
    last_modified_column?: string;
    source_type?: string;
    source_record_type_id?: string;
    combine_behavior?: string;

    include_in_snapshots?: boolean;


    last_build_started?: Date;
    last_build_completed?: Date;
    total_records?: number
    build_error?: string;
    build_status?: string;

    special_node_type?: string;
    special_node_args?: {
        [key: string]: any;
    }


    custom_sql?: string;

    dbt_model_path?: string;
    dbt_schema?: string;

    ignore_pliable_columns_in_custom_sql?: boolean;
    needs_pliable_columns_in_custom_sql?: boolean;

    // seed 
    seed_headers?: string[];
    seed_data?: string[][];
    seed_type?: string;
    generated_from_node_id?: string;
    generated_from_node_column?: string;
    generated_at?: Date;

    // View
    include_historical_data?: boolean;
    upstream_column_ids?: string[];

    cached_generated_sql?: string;


    singular?: string;
    plural?: string;

    dimensions?: PipelineNodeDimension[];
    measures?: PipelineNodeMeasure[];
    calculations?: PipelineNodeCalculation[];

    measure_ids?: string[];
    dimension_ids?: string[];
    extra_report_columns?: PipelineNodeExtraReportColumn[];
    

    done_with_wizard?: boolean;

    alternate_identifier_field_id?: string;
    
    source_id?: string;

    incremental_build?: boolean;
    incremental_timestamp_field_id?: string;

}

export class _PipelineNodeORM extends ORM<PipelineNode>{

    public async queueDownloadJob(nodeId: string, searchText: string) {
        const result = await this.apiService.request('POST', `/${this.endpoint}/${nodeId}/download`, {
            searchText: searchText,
        }) as JobEnqueueResponse;
        return await BackgroundService.getInstance().waitForJob(result.job_id);
    }
    public async deleteFile(nodeId: string, fileId: string) {
        const result = await this.apiService.request('DELETE', `/${this.endpoint}/${nodeId}/delete-file/${fileId}`, {
        }) as JobEnqueueResponse;
        return await BackgroundService.getInstance().waitForJob(result.job_id);
    }

    public async copyPipelineNode(pipelineNodeId: string, newName: string) {
        const result = await ApiService.getInstance().request('POST', `/pipelinenodes/${pipelineNodeId}/copy`, {
            name: newName
        }) as SingleRecordResponse<PipelineNode>;
        return result.record;
    }
};

export const PipelineNodeORM = new _PipelineNodeORM('pipelinenodes', ['last_build_started', 'last_build_completed']);

export interface PipelineNodeRelationshipParentLookup {
    parent_field_id: string;
    child_field_id: string;
}

export interface PipelineNodeRelationshipDenormalizedField {
    parent_field_id: string;
    output_field_name: string;
}

export interface PipelineNodeRelationship extends BaseModel {
    parent_node_id: string;
    child_node_id: string;
    name: string;
    description: string;
    child_foreign_key_name: string;
    parent_lookup_logic_gate: string;
    parent_lookups: PipelineNodeRelationshipParentLookup[];
    denormalized_fields?: PipelineNodeRelationshipDenormalizedField[];

    issue_orphan_records?: number;
    issue_ambiguous_records?: number;
}

export const PipelineNodeRelationshipORM = new ORM<PipelineNodeRelationship>('pipelinenoderelationships');

export const useNodesThatNeedBuilding = () => {
    return useQuery(['pipeline_nodes_that_need_building'], async () => {
        const result = await ApiService.getInstance().request('GET', '/mission_control/needs-building') as ListRecordsResponse<string>;
        return result.records;
    })
}

const numberOfNodesInJoinTree = (joinTree: PipelineNodeMeasureJoinTree) => {
    let count = 1;
    joinTree.downstream.forEach(dt => {
        count += numberOfNodesInJoinTree(dt);
    });
    return count;
}

export const useReportingPaths = (rootNodeId: string, includeNodeIds: string[]) => {
    const cacheID = ['get_reporting_paths', rootNodeId, includeNodeIds.join(',')];
    return useQuery(cacheID, async () => {
        if (!rootNodeId) {
            return [];
        }
        const result = await ApiService.getInstance().request('GET', `/pipelinenodes/${rootNodeId}/get-paths`, {
            include_node_ids: includeNodeIds.join(',')
        }) as ListRecordsResponse<PipelineNodeMeasureJoinTree>;

        return result.records.sort((a, b) => {
            const aCount = numberOfNodesInJoinTree(a);
            const bCount = numberOfNodesInJoinTree(b);
            return aCount - bCount;
        });
    })
}

export const getPipelineNodeData = async (pipelineNodeId: string, perPage?: number, page?: number) => {
    if (!pipelineNodeId) {
        return [];
    }

    const result = await ApiService.getInstance().request('GET', `/pipelinenodes/${pipelineNodeId}/data`, {
        limit: perPage || 25,
        page: page || 1
    }) as ListRecordsResponse<any>;
    return result.records;
}



interface RecordDataResponse {
    record: Record<string, any>;
    relationships?: {
        [relId: string]: Record<string, any>[]|null;
    };
    measures?: {
        measure_indices: number[];
        data: Record<string, any>[];
        key: string;
    }[];
}

interface MeasureDrilldownResponse {
    data: Record<string, any[]>[];
}

export const usePipelineNodeReportDrilldown = (pipelineNodeId: string, recordUuid: string, measureKey: string, skip: boolean) => {
    return useQuery(['pipeline_node_report_drilldown', pipelineNodeId, recordUuid, measureKey, skip], async () => {
        if (!pipelineNodeId || !recordUuid || !measureKey || !!skip) {
            return null;
        }
        const result = await ApiService.getInstance().request('GET', `/pipelinenodes/${pipelineNodeId}/data/${recordUuid}/drilldown/${measureKey}`) as MeasureDrilldownResponse;
        return result;
    });
}

export const usePipelineNodeRecordData = (pipelineNodeId: string, recordUuid: string) => {
    return useQuery(['pipeline_node_record_data', pipelineNodeId, recordUuid], async () => {
        console.log('Getting for', pipelineNodeId, recordUuid);
        if (!pipelineNodeId || !recordUuid) {
            return null;
        }
        const result = await ApiService.getInstance().request('GET', `/pipelinenodes/${pipelineNodeId}/data/${recordUuid}`) as RecordDataResponse;
        return result;
    });
}

export const usePipelineNodeData = (pipelineNodeId: string, perPage?: number, page?: number) => {
    return useQuery(['pipeline_node_data', pipelineNodeId, perPage, page], async () => {
        return getPipelineNodeData(pipelineNodeId, perPage, page);
    })
}

export function useNodeColumnLabels() {
    const pipelineNodes = usePipelineNodes();

    return useMemo(() => {
        // We need to keep track of what columns are ACTUALLY called so we can show the correct names in the mapping. Underneath we're still using the keys so this is just for display.
        if (!pipelineNodes.data) {
            return {};
        }

        const labels: {
            [key: string]: {
                [key: string]: string;
            }
        } = {};

        pipelineNodes.data.forEach(srt => {
            const theseLabels: {
                [key: string]: string;
            } = {}

            srt.fields!.forEach(c => {
                theseLabels[c.id] = c.label;
            });

            labels[srt.id as string] = theseLabels;
        });

        return labels;
    }, [pipelineNodes.dataUpdatedAt]);
}

export function useNodeColumnLabeler() {
    const labels = useNodeColumnLabels();

    return useCallback((pipelineNodeId: string, columnId: string) => {
        if (pipelineNodeId in labels && columnId in labels[pipelineNodeId]) {
            return labels[pipelineNodeId][columnId];
        } else if (columnId === '_PLB_UUID') {
            return 'Pliable Record ID'
        }
        
        return '<NO LABEL>';
    }, [labels]);
}


