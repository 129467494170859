/**
 * CHANGES:
 * 
 * Add filter to search for columns across all data sources (under the header)
 * Show active data source and collapse all otehrs when you click on a mission control edge
 * Hide delete buttons until hover
 * Add placeholder drop
 * Change "fields" header to "{bo.name} Fields"
 * Add "One row per _____" under the Fields header
 * Allow dragging data sources from data library
 * Click to edit field name
 * 
 * Add "special plb fields" to the data source (use SourceRecordType.column_preferences for special static fields)
 *  - Loaded At
 *  - Source Name
 *  - Add your own static field
 * 
 */


import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import 'reactflow/dist/style.css';
import { useNavigate, useParams, useSearchParams, unstable_useBlocker, Link } from "react-router-dom";
import { Updater, useImmer } from 'use-immer';
import {  usePipelineNodes, useProjectConfig, usePipelineNodeRelationships, useIsInDraftMode, usePipelineNode, } from '@stores/data.store';
import styled, { css } from 'styled-components';
import { Badge, Collapse, Form, Modal, Offcanvas, Spinner, Tab, Tabs } from "react-bootstrap";

import produce from "immer";
import { alert, confirmation, requireConfirmation } from "@services/alert/alert.service";
import DataSourceSelectModal from "@pages/Sources/DataSourceSelectModal.component";

import { DndProvider, DragSourceMonitor, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useDebounce, useDebouncedCallback, useThrottledCallback } from "use-debounce";
import BusinessObjectFieldTypeSelector from "@components/businessObjects/BusinessObjectFieldTypeSelector.component";
import Dropdown, { Option } from "@components/form/Dropdown.component";
import Danger from "@components/statusIndicators/Danger.component";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import { NodeFieldJoinPath, PipelineNode, PipelineNodeField, PipelineNodeMapOption, PipelineNodeRelationship } from "@models/pipelineNode";

import PipelineNodeColumnDrawer, { ColumnDistro, ColumnRecordInfo } from "@components/pipelineNodes/PipelineNodeColumnDrawer.component";
import Editor from "@monaco-editor/react";
import PipelineNodeFieldTranslation from "@components/pipelineNodes/PipelineNodeFieldTranslation.component";
import { shortid } from "@services/id.service";
import Warning from "@components/statusIndicators/Warning.component";
import { Seed, useTranslationSeed } from "@models/seed";
import WarningStatusIndicator from "@components/statusIndicators/WarningStatusIndicator.component";
import PipelineNodeFieldEditor from "./mapping/PipelineNodeFieldEditor.component";
import toast from "@services/toast.service";
import PipelineNodeConnector from "./PipelineNodeConnector.component";
import PipelineNodeSelector from "./PipelineNodeSelector.component";
import { isValidUpstreamNode } from "@services/modeling.service";
import { DraftOnly } from "@components/project/DraftModeRequired.component";
import PipelineNodeName, { PipelineNodeFieldName } from "./PipelineNodeName.component";
import { DragDropContext, Droppable, Draggable, DraggingStyle, NotDraggingStyle } from "react-beautiful-dnd";
import { draftToHtml } from "modules/rfb/functions";
import { set } from "immer/dist/internal";
import DataWhitelistForm from "./PipelineNodeDataWhitelist.component";
import PipelineNodeBasicConfiguration from "./configuration/PipelineNodeBasicConfiguration.component";
import PipelineNodeWhitelistConfiguration from "./configuration/PipelineNodeWhitelistConfiguration.component";
import PipelineNodeOutputConfiguration from "./configuration/PipelineNodeOutputConfiguration.component";


const SYSTEM_FIELD_TYPES = ['DENORMALIZED', 'ID', 'FOREIGN_KEY']

interface Transformer {
    label: string;
    description: string;
    value: string;
    takesArguments?: boolean;
    snowflakeOnly?: boolean;
}



const Grid = styled.div`
display: grid;
gap: 20px;
grid-template-columns: repeat(3, 1fr);
`

const ColumnOption = styled.div<{mapped: boolean, highlight?: boolean}>`
display: inline-block;
margin-right: .5rem;
margin-bottom: .5rem;
background: var(--ct-border-color);
padding: .5rem;
color: black;
border-radius: 5px;

${props => 
    props.mapped && css`
        background: var(--pliable-blue);
        color: white;

        &:hover {
            background: var(--pliable-blue) !important;
            color: white !important;
        }
    `

}

${props => props.highlight && css`
    background: var(--pliable-yellow);
    color: white;

    &:hover {
        background: #e58f00 !important;
        color: white !important;
    }
`}

&:hover {
    background: #ccc;
    cursor: pointer;


}

.inner {
    display: flex;
    align-items: center;

    .text {
        flex: 1;
    }

}
`

interface RelatedNode {
    node: PipelineNode;
    relationship: PipelineNodeRelationship;
}


interface Props {
    node: PipelineNode;
    onChange: Updater<PipelineNode>
    enableMerge?: boolean;
    requireMerge?: boolean;
    limitToSingleSource?: boolean;
    includeRelationships?: boolean;
    mergeTextMode?: 'MERGE' | 'GROUP_BY' | 'IDENTIFY';
    useGroupByInsteadOfMerge?: boolean;
    limitToSingleFieldMap?: boolean;
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
  
    return result;
};

const PipelineNodeMappingConfigForm = (props: Props) => {
    const pipelineNodes = usePipelineNodes();
    const relationships = usePipelineNodeRelationships(props.node.id as string);
    
    const navigate = useNavigate();

    const [showAddSourceModal, setShowAddSourceModal] = useState(false);
    
    const addNewFieldAtTop = useCallback(() => {
        const newId = shortid();
        props.onChange(draft => {
            draft.fields.unshift({
                id: newId,
                name: '',
                description: '',
                part_of_composite_key: false,
                label: 'New Field', 
                type: 'STRING',
                map_options: [],
                taxonomic_id: '',
                cell_actions: [],
            })
        })
       
        setActiveFieldId(newId);
    }, [props.onChange]);

    const onDeleteField = useCallback((field: PipelineNodeField) => {
        props.onChange(draft => {
            const idx = draft.fields.findIndex(d => d.id === field.id);
            if (idx >= 0) {
                draft.fields.splice(idx, 1);
            }
        })
    }, [props.onChange]);

    const [selectedFieldIds, setSelectedFieldIds] = useImmer<string[]>([]);


    const [searchInput, setSearchInput] = useState('');
    const [searchTerm] = useDebounce(searchInput, 250);

    const [boSearchInput, setBoSearchInput] = useState('');
    const [boSearchTerm] = useDebounce(boSearchInput, 250);

    const usedFields = useMemo(() => {
        const used: string[] = [];
        props.node.fields.forEach(f => {
            f.map_options?.forEach(mo => {
                if (mo.source_node_id) {
                    used.push(mo.source_node_id + ':' + mo.attribute_key);
                } else if (mo.sub_options) {
                    mo.sub_options.forEach(so => {
                        used.push(so.source_node_id + ':' + so.attribute_key);
                    });
                }
            });
        });
        return used;
    }, [props.node.fields])


    const filteredColumns = useMemo(() => {
        if (!pipelineNodes.data) {
            return []
        }
        const term = searchTerm.toLowerCase();


        return props.node.upstream_node_ids.map(upstreamNodeId => {
            const upstreamNode = pipelineNodes.data.find(s => s.id === upstreamNodeId);
            const theseFilteredColumns = upstreamNode?.fields?.filter(c => c.label.toLowerCase().indexOf(term) >= 0 || c.label.toLowerCase().indexOf(term) >= 0).sort((a, b) => {
                if (a.label > b.label) {
                    return 1;
                }
                return -1;
            });


            // Now break them into used/unused
            if (!theseFilteredColumns) {
                return {
                    'used': [],
                    'unused': [],
                }
            } 
            return {
                'used': theseFilteredColumns.filter(c => usedFields.includes(upstreamNodeId + ':' + c.name)),
                'unused': theseFilteredColumns.filter(c => !usedFields.includes(upstreamNodeId + ':' + c.name)),
            }

        });
    }, [props.node.upstream_node_ids, searchTerm, pipelineNodes.dataUpdatedAt, usedFields]);


    const mergeText = useMemo(() => {
        const compositeFieldNames = props.node.fields.filter(f => f.part_of_composite_key).map(f => f.label);
        let joiner: string = ' and ';
        if (props.node.combine_logic_gate == 'OR') {
            joiner = ' or ';
        }
        if (compositeFieldNames.length) {
            let fieldText = '';
            if (compositeFieldNames.length === 1) {
                fieldText = compositeFieldNames[0];
            } else if (compositeFieldNames.length === 2) {
                fieldText = compositeFieldNames.join(joiner);
            } else {
                let last = compositeFieldNames.pop();
                fieldText = compositeFieldNames.join(', ') + ', ' + joiner + last;
            }
            return fieldText;
        }
        return '';

        


    }, [props.node.fields, props.node.combine_logic_gate]);

    const toggleFieldCompositeKey = useCallback((fieldId: string) => {
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === fieldId);
            if (theField) {
                theField.part_of_composite_key = !theField.part_of_composite_key;
            }
        })
        
    }, [props.onChange]);

    
    const onUnmapColumn = useCallback((sourceNodeId: string, sourceColumnId: string, targetColumnId: string) => {
        const sourceColumn = pipelineNodes.data?.find(s => s.id === sourceNodeId)?.fields?.find(f => f.id === sourceColumnId);
        if (!sourceColumn) {
            return;
        }
        props.onChange(draft => {
            const theField = draft.fields.find(f => f.id === targetColumnId);
            if (!theField) {
                return;
            }

            if (!theField.map_options) {
                return;
            }

            const idx = theField.map_options.findIndex(mo => mo.source_node_id === sourceNodeId && mo.attribute_id === sourceColumnId);
            if (idx >= 0) {
                theField.map_options.splice(idx, 1);
            }

        });
        toast('success', 'Success', `Column unmapped`, 2000);

        
    }, [props.onChange, pipelineNodes.dataUpdatedAt]);

    const onMapColumn = useCallback((sourceNodeId: string, sourceColumnId: string, targetColumnId: string) => {
        const sourceColumn = pipelineNodes.data?.find(s => s.id === sourceNodeId)?.fields?.find(f => f.id === sourceColumnId);
        if (!sourceColumn) {
            return '';
        }

        let fieldId: string;

        if (targetColumnId == 'NEW_TOP') {
            fieldId = shortid();
        } else {
            fieldId = targetColumnId;
        }

        props.onChange(draft => {
            const fieldType = (SYSTEM_FIELD_TYPES.includes(sourceColumn.type)) ? 'STRING' : sourceColumn.type;
            if (targetColumnId == 'NEW_TOP') {
                draft.fields.unshift({
                    id: fieldId,
                    label: sourceColumn.label,
                    name: sourceColumn.label,
                    part_of_composite_key: false,
                    type: fieldType,
                    taxonomic_id: '',
                    description: '',
                    map_options: [
                        {
                            id: shortid(),
                            source_node_id: sourceNodeId,
                            attribute_key: sourceColumn.name,
                            attribute_id: sourceColumn.id,
                            combination_rule: 'PICK_ONE',

                        }
                    ],
                    cell_actions: []
                });

            } else {
                const theField = draft.fields.find(f => f.id === targetColumnId);
                if (!theField) {
                    throw new Error('field not found');
                }


                if (!theField.map_options) {
                    theField.map_options = [];
                }

                if (props.limitToSingleFieldMap && theField.map_options.length >= 1) {
                    theField.map_options = [];
                }

                theField.map_options.push({
                    id: shortid(),
                    source_node_id: sourceNodeId,
                    attribute_key: sourceColumn.name,
                    attribute_id: sourceColumn.id,
                    combination_rule: 'PICK_ONE', 
                });


            }
            
        });
        setAddingColumn('');
        return fieldId;

    }, [props.onChange, pipelineNodes.dataUpdatedAt]);
        

    const mergingFields = useMemo(() => {
        return props.node.fields.filter(f => f.part_of_composite_key);
    }, [props.node.fields]);

    const allFields = useMemo(() => {
        const lower = boSearchTerm.toLowerCase();
        return props.node.fields.filter(f => !SYSTEM_FIELD_TYPES.includes(f.type) && f.label.toLowerCase().indexOf(lower) >= 0);
    }, [props.node.fields, boSearchTerm])

    const addAllFieldsFromSource = useCallback((upstreamNodeId: string) => {
        if (!pipelineNodes.data) {
            return;
        }

        const theSrt = pipelineNodes.data.find(s => s.id === upstreamNodeId);
        if (!theSrt) {
            return;
        }

        const idx = props.node.upstream_node_ids.indexOf(upstreamNodeId);

        const unusedFields = filteredColumns[idx].unused;
        props.onChange(draft => {

            unusedFields.forEach(f => {
                // Find a BO field with the same name and add it if so, otherwise create
                const mapOption = {
                    id: shortid(),
                    source_node_id: upstreamNodeId,
                    attribute_key: f.name,
                    attribute_id: f.id,
                    combination_rule: 'PICK_ONE', 
                };
                const fieldWithSameName = draft.fields.find(field => field.label.toLowerCase() == f.label.toLowerCase());
                if (fieldWithSameName) {
                    if (!fieldWithSameName.map_options) {
                        fieldWithSameName.map_options = [];
                    }

                    fieldWithSameName.map_options.push(mapOption);

                } else {
                    draft.fields.push({
                        id: shortid(),
                        name: f.label,
                        label: f.label,
                        part_of_composite_key: false,
                        type: f.type,
                        map_options: [mapOption],
                        taxonomic_id: '',
                        description: '',
                        cell_actions: [],
                    })
                }
            })
        });


    }, [props.onChange, pipelineNodes.dataUpdatedAt, props.node.upstream_node_ids]);
    
    const [activeColumnPipelineNodeId, setActiveColumnPipelineNodeId] = useState('');
    const [activeColumn, setActiveColumn] = useState<PipelineNodeField|undefined>(undefined);


    const [activeFieldForTranslations, setActiveFieldForTranslations] = useState<PipelineNodeField|undefined>(undefined);
    const onEditFieldTranslations = useCallback((fieldId: string) => {
        const theField = props.node.fields.find(f => f.id === fieldId);
        if (theField) {
            setActiveFieldForTranslations(theField);
        }
    }, [props.node.fields]);

    const [activeFieldId, setActiveFieldId] = useState('');
    const activeField = useMemo(() => {
        if (activeFieldId) {
            const af = props.node.fields.find(f => f.id === activeFieldId);
            return af;
        }
        return undefined;
    }, [activeFieldId, props.node.fields]);

    const onUpdateActiveFieldAttribute = useCallback((attr: keyof PipelineNodeField, value: any) => {
        props.onChange(draft => {
            const idx = draft.fields.findIndex(f => f.id === activeFieldId);
            if (idx >= 0) {
                // @ts-ignore
                draft.fields[idx][attr] = value;

            }
        });
        
    }, [props.onChange, activeFieldId])

    const fieldsForAutocomplete = useMemo(() => {
        if (!activeField) {
            return [];
        }

        // Can't use other formulas in a formula, just to be safe for order of operations
        return props.node.fields.filter(f => f.id !== activeField.id && !f.advanced_mode).map(f => f.name)
    }, [props.node.fields, activeField]);


    const [newSourceSelection, setNewSourceSelection] = useState('');

    const newSourceOptionFilter = useCallback((pn: PipelineNode) => {
        return isValidUpstreamNode(props.node, pn);
    }, [props.node.upstream_node_ids, props.node])

    const onConnectSource = useCallback(async () => {
        props.onChange(draft => {
            draft.upstream_node_ids.push(newSourceSelection);
        })
        setShowAddSourceModal(false);
        
    }, [props.onChange, newSourceSelection]);

   

    const updateUpstreamNodeIds = useCallback((newUpstreamIds: string[]) => {
        // Updates the upstreamNodeIds and removes any mapped fields for no-longer-connected upstream nodes
        props.onChange(draft => {
            draft.upstream_node_ids = newUpstreamIds;

            draft.fields.forEach(f => {
                if (f.map_options) {
                    const newMapOptions: PipelineNodeMapOption[] = [];
                    f.map_options.forEach(mo => {
                        if (mo.sub_options) {
                            const newSubOptions: PipelineNodeMapOption[] = [];
                            mo.sub_options.forEach(so => {
                                if (newUpstreamIds.includes(so.source_node_id as string)) {
                                    newSubOptions.push(so);
                                }
                            });
                            mo.sub_options = newSubOptions;
                        }
                        if (newUpstreamIds.includes(mo.source_node_id as string)) {
                            newMapOptions.push(mo);
                        }
                    });
                    f.map_options = newMapOptions;
                }
            })
        })
    }, [props.onChange]);

    const removeUpstreamNode = useCallback(async (upstreamNodeId: string) => {
        const confirmed = await requireConfirmation('Are you sure you want to remove this source?');
        if (confirmed) {
            const newUpstreamIds = props.node.upstream_node_ids.filter(id => id !== upstreamNodeId);
            updateUpstreamNodeIds(newUpstreamIds);
        }
        
    }, [updateUpstreamNodeIds, props.node.upstream_node_ids]);

    const inDraftMode = useIsInDraftMode();

    const [showColumnEditor, setShowColumnEditor] = useState(false);

    const mappedFieldsBySourceNode = useMemo(() => {
        const mappedFields: {[key: string]: string[]} = {};
        props.node.fields.forEach(f => {
            f.map_options?.forEach(mo => {
                if (!mappedFields[mo.source_node_id as string]) {
                    mappedFields[mo.source_node_id as string] = [];
                }
                mappedFields[mo.source_node_id as string].push(mo.attribute_id as string);

                if (mo.sub_options) {
                    mo.sub_options.forEach(so => {
                        if (!mappedFields[so.source_node_id as string]) {
                            mappedFields[so.source_node_id as string] = [];
                        }
                        mappedFields[so.source_node_id as string].push(so.attribute_id as string);
                    });
                }
            });
        });
        return mappedFields;
    }, [props.node.upstream_node_ids, props.node.fields]);

    const columnsThatSourceColumnsAreMappedTo = useMemo(() => {
        const mappedColumns: {
            [nodeId: string]: {
                [key: string]: string[]
            }
        } = {};

        const addMapping = (sourceNodeId: string, sourceColumnId: string, targetColumnId: string) => {
            if (!mappedColumns[sourceNodeId]) {
                mappedColumns[sourceNodeId] = {};
            }
            if (!mappedColumns[sourceNodeId][sourceColumnId]) {
                mappedColumns[sourceNodeId][sourceColumnId] = [];
            }
            mappedColumns[sourceNodeId][sourceColumnId].push(targetColumnId);
        }
        props.node.fields.forEach(f => {
            f.map_options?.forEach(mo => {
                if (mo.attribute_id) {
                    addMapping(mo.source_node_id as string, mo.attribute_id as string, f.id as string);
                }
                

                if (mo.sub_options) {
                    mo.sub_options.forEach(so => {
                        if (so.attribute_id) {
                            addMapping(so.source_node_id as string, so.attribute_id as string, f.id as string);
                        }
                    });
                }
            });
        });
        return mappedColumns;
    }, [props.node.fields]);

    const sourceNodeFieldCount = useMemo(() => {
        const count: {[key: string]: number} = {};

        /**
         * Get the total number of columns in each source node
         */
        pipelineNodes.data?.forEach(pn => {
            count[pn.id as string] = pn.fields.length;
        });
        return count;
        
        
    }, [pipelineNodes.dataUpdatedAt]);


    const [expandedDataSource, setExpandedDataSource] = useState('');

    const expandedSourceNode = usePipelineNode(expandedDataSource);

    const expandedSourceColumns = useMemo(() => {
        if (!expandedDataSource) {
            return [];
        }
        if (!expandedSourceNode.data) {
            return [];
        }
        return [...expandedSourceNode.data.fields].sort((a, b) => {
            if (a.label > b.label) {
                return 1;
            }
            return -1;
        });
    }, [expandedSourceNode.dataUpdatedAt, expandedDataSource]);

    const isDraggingDisabled = useMemo(() => {
        return !inDraftMode || !!boSearchTerm;
    }, [boSearchTerm, inDraftMode]);

    const getItemStyle = (isDragging: boolean, draggableStyle: DraggingStyle | NotDraggingStyle | undefined) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: "none",
      
        // change background colour if dragging
        // background: isDragging ? "lightgreen" : "grey",

        display: isDragging ? 'table' : 'table-row',

        borderTop: isDragging ? '1px solid var(--ct-border-color)' : 'none',
        backgroundColor: isDragging ? 'white' : 'inherit',
        borderLeft: isDragging ? '1px solid var(--ct-border-color)' : 'none',
        borderRight: isDragging ? '1px solid var(--ct-border-color)' : 'none',
      
        // styles we need to apply on draggables
        ...draggableStyle
    });

    useEffect(() => {
        if (!!activeFieldId) {
            setShowColumnEditor(true);
        }
    }, [activeFieldId]);

    const onColumnReorder = useCallback((result: any) => {
        if (!result.destination) {
            return;
        }

        
        props.onChange(draft => {
            // Retain the fields that aren't managed here.
            const unmanagedFields = draft.fields.filter(f => SYSTEM_FIELD_TYPES.includes(f.type));
            const managedFields = draft.fields.filter(f => !SYSTEM_FIELD_TYPES.includes(f.type));
            const newFields = reorder(managedFields, result.source.index, result.destination.index);
            draft.fields = newFields.concat(unmanagedFields);
        });
    }, [props.onChange]);

    const onSaveEditingField = useCallback((editingField: PipelineNodeField) => {
        props.onChange(draft => {
            const idx = draft.fields.findIndex(f => f.id === editingField.id);
            if (idx >= 0) {
                draft.fields[idx] = editingField;
            }
        });
        setActiveFieldId('');
        setShowColumnEditor(false);
    }, [activeFieldId, props.onChange]);

    const [addingColumn, setAddingColumn] = useState('');
    const [addingColumnNodeId, setAddingColumnNodeId] = useState('');
    const [showSourceColumnDetails, setShowSourceColumnDetails] = useState(false);  

    const onAddColumn = useCallback((nodeId: string, columnId: string) => {
        setAddingColumnNodeId(nodeId);
        setAddingColumn(columnId);
        setShowSourceColumnDetails(false);
    }, []);


    return <div >
        <Offcanvas
            show={showColumnEditor}
            onHide={() => {
                setShowColumnEditor(false);
                setActiveFieldId('');
            }}
            placement="end"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>
                    Configure Column
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>

                        
                        
                        {activeField && <>
                            
                            <PipelineNodeFieldEditor 
                                pipelineNodeId={props.node.id as string}
                                key={activeField.id} 
                                field={activeField} 
                                allFields={props.node.fields}
                                onChange={onUpdateActiveFieldAttribute}
                                isMerging={!!mergeText}
                                combineBehavior={props.node.combine_behavior}
                                onEditTranslations={onEditFieldTranslations}
                                otherFieldsForAutocomplete={fieldsForAutocomplete}
                                disableMultiple={props.node.node_type == 'DATAMART'}
                                displayJoinPath={true}
                                upstreamNodeIds={props.node.upstream_node_ids}
                                onSave={onSaveEditingField}
                                onCancel={() => {
                                    setActiveFieldId('');
                                    setShowColumnEditor(false);
                                }}
                            />
                        </>}
                    </PaneContent>
                </Pane>
            </Offcanvas.Body>
        </Offcanvas>
        <Modal size="lg" show={!!activeFieldForTranslations} onHide={() => setActiveFieldForTranslations(undefined)}>
            {activeFieldForTranslations && (
                <PipelineNodeFieldTranslation
                    pipelineNodeId={props.node.id as string}
                    fieldId={activeFieldForTranslations.id}
                />
            )}
            
        </Modal>
        <PipelineNodeColumnDrawer
            pipelineNodeId={activeColumnPipelineNodeId}
            column={activeColumn}
            onHide={() => {
                setActiveColumnPipelineNodeId('');
                setActiveColumn(undefined);
            }}
            show={!!activeColumn && !!activeColumnPipelineNodeId}
        />
        <>
            
            <Modal show={showAddSourceModal} onHide={() => setShowAddSourceModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Connect Source</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Select new source node</Form.Label>
                        <PipelineNodeSelector
                            onSelect={(pn: PipelineNode|undefined) => {

                                setNewSourceSelection(pn ? pn.id as string : '');
                            }}
                            selectedId={newSourceSelection}
                            optionFilter={newSourceOptionFilter}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <button disabled={!newSourceSelection} className="btn btn-pliable" onClick={onConnectSource}>Add Source</button>
                </Modal.Footer>
            </Modal>
                        <PipelineNodeBasicConfiguration node={props.node} onChange={props.onChange}/>
                        <hr />
                        <div className="mb-3">
                            <div className="d-flex center-vertically mb-1">

                                <h2 className="mb-0 flex-1">
                                    <i className="mdi mdi-transit-connection-variant"></i> Connected Data ({props.node.upstream_node_ids.length})
                                </h2>
                                <DraftOnly>
                                    <button className="btn btn-sm btn-outline-primary" disabled={props.node.upstream_node_ids.length == 1 && props.limitToSingleSource} onClick={() => {
                                        setShowAddSourceModal(true);
                                    }}>
                                        <i className="mdi mdi-plus-circle"></i> New Connection
                                    </button>
                                </DraftOnly>
                                
                            </div>
                            <p className="mb-0">Map data from connected sources to columns in your node. This node type allows you to connect <strong>{props.limitToSingleSource ? 'one source' : 'multiple sources'}</strong>.</p>  
                        </div>
                        <Grid>
                            {props.node.upstream_node_ids.map((upstreamNodeId: string, idx) => {
                                return <div key={idx} className="shadow-box p-3">
                                    <div className="flex">
                                        <div className="flex-1">
                                            <h3><PipelineNodeName pipelineNodeId={upstreamNodeId} link/></h3>
                                            <p>
                                                <a role="button" onClick={() => {
                                                    setExpandedDataSource(upstreamNodeId);
                                                }}><strong>{mappedFieldsBySourceNode[upstreamNodeId] ? mappedFieldsBySourceNode[upstreamNodeId].length : '0'}</strong> out of <strong>{sourceNodeFieldCount[upstreamNodeId]}</strong> columns mapped</a>
                                            </p>
                                
                                        </div>
                                        <div>
                                            
                                            <button className="btn btn-sm btn-outline-secondary" onClick={() => {
                                                setExpandedDataSource(upstreamNodeId);
                                            }}>
                                                <i className="mdi mdi-table-column-plus-after"></i> Map Columns
                                            </button>
                                            <DraftOnly>
                                                <button className="btn btn-sm btn-outline-danger ms-1" onClick={() => {
                                                    removeUpstreamNode(upstreamNodeId);
                                                }}>
                                                    <i className="mdi mdi-connection"></i> Disconnect
                                                </button>
                                            </DraftOnly>
                                            
                                        </div>
                                    </div>
                                </div>
                            })}
                        </Grid>
                        <Offcanvas show={!!expandedDataSource} onHide={() => {
                            setExpandedDataSource('')
                            setAddingColumn('');
                            setAddingColumnNodeId('');
                        }} placement="end">
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title><PipelineNodeName pipelineNodeId={expandedDataSource}/></Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                {!!addingColumn && <div style={{height:'100%'}}>
                                    <Pane>
                                        <PaneContent>
                                            <div className="p-3">
                                                <button className="btn btn-outline-secondary" onClick={() => {
                                                    setAddingColumn('');
                                                }}>&larr; Back to columns list</button>
                                                <hr />

                                            <div className="mb-2">
                                                <ColumnRecordInfo pipelineNodeId={addingColumnNodeId} columnId={addingColumn}/>
                                            </div>
                                            <div className="mb-2">
                                            <button className="icon-button" onClick={() => {
                                                setShowSourceColumnDetails(!showSourceColumnDetails);
                                            }}>
                                                {showSourceColumnDetails && <span>
                                                    <i className="mdi mdi-eye-off-outline"></i> Hide Samples    
                                                </span>}
                                                {!showSourceColumnDetails && <span>
                                                    <i className="mdi mdi-eye"></i> View Samples    
                                                </span>}
                                            </button>
                                            </div>
                                            <Collapse in={showSourceColumnDetails}>
                                                <>
                                                    {showSourceColumnDetails && <ColumnDistro pipelineNodeId={addingColumnNodeId} columnId={addingColumn}/>}

                                                </>
                                            </Collapse>
                                            
                                            <hr />
                                            <h3>Use Column</h3>
                                            <DraftOnly>
                                                <p className="small">Select a column to map this to.</p>

                                            </DraftOnly>
                                            <div>
                                                <ColumnOption mapped={false} highlight onClick={() => {
                                                    if (inDraftMode) {
                                                        onMapColumn(addingColumnNodeId, addingColumn, 'NEW_TOP');
                                                    }
                                                    
                                                    
                                                }}>
                                                    <strong>Map to New Column</strong>
                                                </ColumnOption>
                                                {allFields.map(f => {
                                                    const mapped = columnsThatSourceColumnsAreMappedTo[addingColumnNodeId] && columnsThatSourceColumnsAreMappedTo[addingColumnNodeId][addingColumn] && columnsThatSourceColumnsAreMappedTo[addingColumnNodeId][addingColumn].includes(f.id);
                                                    return <ColumnOption key={f.id} mapped={mapped} onClick={() => {
                                                        if (!inDraftMode) {
                                                            return;
                                                        }
                                                        if (mapped) {
                                                            onUnmapColumn(addingColumnNodeId, addingColumn, f.id);
                                                        } else {
                                                            onMapColumn(addingColumnNodeId, addingColumn, f.id);

                                                        }
                                                    }}>
                                                        <div className="inner">
                                                            <div className="text">
                                                                <strong>{f.label}</strong>
                                                            </div>
                                                        </div>
                                                    </ColumnOption>
                                                })}
                                            </div>
                                            
                                            </div>

                                        </PaneContent>
                                    </Pane>
                                </div>}
                                <Pane>
                                    <PaneContent>
                                        {!addingColumn && <div className="p-3">
                                        
                                            <div className="d-flex center-vertically">
                                                <h2 className="mb-0 flex-1">Column Options</h2>
                                                <DraftOnly>
                                        
                                                    <button className="btn btn-outline-secondary btn-sm me-3" onClick={() => {
                                                        addAllFieldsFromSource(expandedDataSource);
                                                        setExpandedDataSource('');
                                                    }}>
                                                        <i className="mdi mdi-plus-circle"></i> Add All
                                                    </button>
                                                </DraftOnly>
                                            </div>
                                            <DraftOnly>
                                                <p className="small text-muted">Click a source column to map it to a column on this node.</p>
                                                
                                            </DraftOnly>
                                            <div>
                                                {expandedSourceColumns.map(c => {
                                                    return <ColumnOption key={c.id} mapped={mappedFieldsBySourceNode[expandedDataSource]?.includes(c.id)} onClick={() => {
                                                        if (!inDraftMode) {
                                                            return;
                                                        }
                                                        onAddColumn(expandedDataSource, c.id);
                                                    }}>
                                                        <div className="inner">
                                                            <div className="text">
                                                                <strong>{c.label}</strong>
                                                                
                                                            </div>
                                                            
                                                        </div>
                                                    </ColumnOption>
                                                })}
                                            </div>
                                        </div>}
                                    </PaneContent>
                                </Pane>
                            </Offcanvas.Body>
                        </Offcanvas>
                        
                        <hr />
                        <div className="mb-3">
                            <div className="d-flex center-vertically mb-1">

                                <h2 className="mb-0 flex-1">
                                    <i className="mdi mdi-table-column"></i> Columns ({allFields.length})
                                </h2>
                                <div className="pe-5" style={{width: '40%'}}>
                                    <input type="text" className="search-input" value={boSearchInput} onChange={(e) => setBoSearchInput(e.target.value)} placeholder="Search columns"/>
                                </div>
                                <DraftOnly>
                                    <button className="btn btn-outline-primary btn-sm" onClick={() => {
                                        addNewFieldAtTop();
                                    }}>
                                        <i className="mdi mdi-plus-circle"></i> Add Column
                                    </button>
                                </DraftOnly>
                                
                            </div>
                        </div>
                            
                        <DragDropContext onDragEnd={onColumnReorder}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <table
                                        ref={provided.innerRef}
                                        className="table table-centered table-fixed w-100 table-action"
                                        
                                    > 
                                        <thead className="bg-light">
                                            <tr>
                                                <th style={{width: '5%'}}></th>
                                                {props.enableMerge && <th style={{width: '10%'}}>Merge</th>}
                                                <th style={{width: props.enableMerge ? '40%' : '50%'}}>Name</th>
                                                <th style={{width: '25%'}}></th>
                                                <th style={{width: '20%'}}></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {allFields.map((f, idx) => {
                                            return <Draggable key={f.id} draggableId={f.id} index={idx} isDragDisabled={isDraggingDisabled}>
                                                
                                                {(draggableProvided, snapshot) => {
                                                    if (draggableProvided.draggableProps.style) {
                                                        var transform = draggableProvided.draggableProps.style?.transform
                                                        if(transform){
                                                            var t = transform.split(",")[1]
                                                            draggableProvided.draggableProps.style.transform = "translate(0px," + t
                                                        }
                                                    }
                                                    
                                                    return <tr 
                                                        ref={draggableProvided.innerRef} 
                                                        {...draggableProvided.draggableProps} 
                                                        // @ts-ignore
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            draggableProvided.draggableProps.style
                                                        )}
                                                        
                                                        onClick={() => setActiveFieldId(f.id)}
                                                        className="hover-control"

                                                        
                                                    >
                                                        <td style={{width: '5%'}}>
                                                            {!isDraggingDisabled && <>
                                                                <div {...draggableProvided.dragHandleProps} className="font-18">
                                                                    <i className="mdi mdi-drag"></i>
                                                                </div>
                                                            </>}
                                                            
                                                        </td>
                                                        {props.enableMerge && <td style={{width: '10%'}} className="text-center">
                                                            <Form.Check
                                                                disabled={!inDraftMode}
                                                                label=""
                                                                checked={f.part_of_composite_key}
                                                                onChange={(e) => toggleFieldCompositeKey(f.id)}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                            />
                                                        </td>}
                                                        <td style={{width: props.enableMerge ? '40%' : '50%'}}>
                                                            <strong>{f.label}</strong>
                                                            <div className="small">
                                                                {f.description || 'No description'}
                                                            </div>
                                                        </td>
                                                        <td style={{width: '25%'}}>
                                                            {f.part_of_composite_key && (!f.map_options || (f.map_options.length != props.node.upstream_node_ids.length && props.node.combine_logic_gate == 'AND')) && <>
                                                                <Badge pill bg="danger" className="me-1">Missing mappings</Badge>
                                                            </>}
                                                            {!f.map_options.length && !f.advanced_mode && !f.part_of_composite_key && (
                                                                <Badge pill bg="warning">No mapped columns</Badge>
                                                            )}
                                                            {!f.advanced_mode && f.map_options.length >= 1 && (
                                                                <Badge pill bg="info">{f.map_options.length} mapped column{f.map_options.length > 1 ? 's' : ''}</Badge>
                                                            )}
                                                            {f.advanced_mode && (
                                                                <Badge bg="dark"><i className="mdi mdi-code-braces"></i></Badge>
                                                                
                                                            )}
                                                        </td>
                                                        <td className="text-end font-18" style={{width: '20%'}}>
                                                            <DraftOnly>
                                                                <button onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    onDeleteField(f)
                                                                }} className="btn btn-sm btn-outline-danger ms-3 hover-only">
                                                                    <i className="mdi mdi-close-thick"></i> Remove Column
                                                                </button>
                                                            </DraftOnly>
                                                            
                                                        </td>
                                                        
                                                    </tr>
                                                    }}
                                            </Draggable>
                                        })}
                                        {provided.placeholder}
                                        </tbody>
                                        
                                    </table>
                                )}
                            </Droppable>
                        </DragDropContext>        
                        <hr />
                        
                        {props.enableMerge && (
                                <>
                                    <h2>
                                        <i className="mdi mdi-set-merge"></i> Merging Behavior
                                        
                                    </h2>
                                    {mergeText && <div>
                                       
                                            <div className="mb-1">
                                                {props.mergeTextMode == 'GROUP_BY' && <>
                                                    Output will be grouped by <strong>{mergeText}</strong>
                                                </>}
                                                {(!props.mergeTextMode || props.mergeTextMode == 'MERGE') && <>
                                                    Records with the same values for <strong>{mergeText}</strong> will be merged.

                                                </>}
                                                {props.mergeTextMode == 'IDENTIFY' && <>
                                                    Records with the same values for <strong>{mergeText}</strong> will be assigned the same identity.

                                                </>}
                                            </div>
                                            
                                            {mergingFields.length >= 2 && !props.useGroupByInsteadOfMerge && (
                                                <div>
                                                    <Form.Check
                                                        type="switch"
                                                        disabled={!inDraftMode}
                                                        label="Merge if all columns match"
                                                        checked={props.node.combine_logic_gate === 'AND'}
                                                        onChange={(e) => props.onChange(draft => {
                                                            draft.combine_logic_gate = e.target.checked ? 'AND' : 'OR';
                                                        })}
                                                    />
                                                </div>
                                            )}
                                        
                                    </div>}
                                    {!mergeText && !!props.requireMerge && <div>
                                        <Warning>
                                            <div>You must select at least 1 column to 
                                                {props.mergeTextMode == 'GROUP_BY' && <> group by</>}
                                                {props.mergeTextMode == 'MERGE' && <> merge on</>}
                                                {props.mergeTextMode == 'IDENTIFY' && <> identify records</>}
                                            &nbsp;(<span className="text-pliable"><i className="mdi mdi-key"></i></span>)</div>
                                        </Warning>    
                                    </div>}
                                    {!mergeText && !props.requireMerge && <div>
                                        {allFields.length > 0 && <div>No merge fields selected.</div>}
                                        {allFields.length == 0 && <div>Add some columns to configure merging.</div>}
                                    </div>}
                                                
                                                
                                               
                                                
                                    <hr />            
                                </>
                         )}
                        <PipelineNodeWhitelistConfiguration node={props.node} onChange={props.onChange}/>
                        <hr />
                        <PipelineNodeOutputConfiguration node={props.node} onChange={props.onChange}/>
                        <div className="mb-5"></div>

                        
                        {/* <hr />
                        <h2>Output Settings</h2>       */}

            </>
        
        
        
    </div>
    
}


export default PipelineNodeMappingConfigForm;