import React, { useCallback } from 'react';
import styled from 'styled-components';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import produce from 'immer';
import { Form } from 'react-bootstrap';
import { PipelineNodeField, PipelineNodeShape, ColumnStats } from '@models/pipelineNode';
import { useIsInDraftMode } from '@stores/data.store';

interface Props {
    fields: PipelineNodeField[];
    shape: PipelineNodeShape;
    onClickColumn: (field: PipelineNodeField) => void;
    onChangeNodeField: (newFields: PipelineNodeField[]) => void;
    onSave?: (newFields: PipelineNodeField[]) => void;
}

const Styles = styled.div`
    .header {
        display: flex;
        line-height: 21px;
        font-family: "Poppins";
        padding: 0rem 1rem;

        .column-container {
            width: 50%;
            font-size: 13px;
        }

        .include-container {
            text-align: right;
            width: 50%;
            font-size: 13px;

            .select-all {
                display: flex;
                align-items: center;
            }
        }
    }
`;

interface DraggableColumnProps {
    field: PipelineNodeField;
    index: number;
    onClick: () => void;
    onToggleVisibility: (show_column: boolean) => void;
    columnStats: ColumnStats;
    onSave?: (newFields: PipelineNodeField[]) => void;
    selectAll: boolean; // New prop
}

const DraggableColumnStyles = styled.div`
    width: 100%;
    user-select: none;
    margin: .5rem 0rem;

    &:hover {
        cursor: pointer;
        background-color: var(--ct-light-gray);
    }

    .icon-container {
        padding: 8px 5px;
        border-right: solid 1px var(--ct-border-color);

        &:hover {
            background-color: var(--ct-light-gray);
            cursor: move;
        }
    }

    .name-container {
        padding: 8px 0;
        font-family: "Poppins";
        flex: 1;
        text-overflow: ellipsis;
        padding: 0 .5rem;
        overflow: hidden;
        white-space: nowrap;
        display: flex;
        align-items: center;

        input {
            width: 100%;
        }
    }

    .checkbox-container {
        padding: 8px .5rem;
        text-align: right;
        width: 20%;
        display: flex;
        justify-content: right;
        align-items: top;
    }
`;

const DraggableColumn = (props: DraggableColumnProps) => (
    <Draggable draggableId={props.field.id} index={props.index}>
        {provided => (
            <DraggableColumnStyles
                {...provided.draggableProps}
                ref={provided.innerRef}
                className="shadow-box"
                onClick={props.onClick}
            >
                <div className="d-flex">
                    <div className="icon-container" {...provided.dragHandleProps}>
                        <i className="mdi mdi-drag"></i>
                    </div>
                    <div className="name-container">
                        {props.field.name}
                    </div>
                    <div className="checkbox-container">
                        <Form.Check
                            checked={props.field.show_column}
                            onChange={(e) => props.onToggleVisibility(e.target.checked)}
                        />
                    </div>
                </div>
            </DraggableColumnStyles>
        )}
    </Draggable>
);

const PipelineNodeColumnOrder = (props: Props) => {
    const inDraftMode = useIsInDraftMode();
    const onReorderColumns = useCallback((res: any) => {
        if (!res.destination || res.destination.index === res.source.index) {
            return;
        }

        const newOrder = Array.from(props.fields);
        const [movedItem] = newOrder.splice(res.source.index, 1);
        newOrder.splice(res.destination.index, 0, movedItem);
        props.onChangeNodeField(newOrder);
    }, [props.onChangeNodeField, props.fields]);

    const onChangeVisibility = useCallback((idx: number, show_column: boolean) => {
        const newColumns = produce(props.fields, draft => {
            draft[idx].show_column = show_column;
        });
        props.onChangeNodeField(newColumns);
    }, [props.onChangeNodeField, props.fields]);

    const handleSelectAll = (checked: boolean) => {
        const newColumns = produce(props.fields, draft => {
            draft.forEach(field => {
                field.show_column = checked;
            });
        });
        props.onChangeNodeField(newColumns);
    };

    return (
        <Styles>
            <div className="header">
                <div className="column-container">Columns</div>
                <div className="include-container">Visible</div>
            </div>
            <DragDropContext onDragEnd={onReorderColumns}>
                <Droppable droppableId="main">
                    {provided => (
                        <div {...provided.droppableProps} ref={provided.innerRef} className={!inDraftMode ? 'disabled' : ''}>
                            {props.fields.map((f: PipelineNodeField, idx: number) => {
                                const stats = props.shape?.columns.find(c => c.key === f.id) || {
                                    key: f.id,
                                    distinct_values: 0,
                                    empty_values: 0,
                                    uniqueness: 0,
                                    density: 0,
                                    samples: []
                                };

                                return (
                                    <DraggableColumn
                                        key={f.id}
                                        columnStats={stats}
                                        field={f}
                                        onToggleVisibility={(show_column: boolean) => onChangeVisibility(idx, show_column)}
                                        index={idx}
                                        onClick={() => props.onClickColumn(f)}
                                        onSave={props.onSave}
                                        selectAll={false}
                                    />
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
            <button className={`btn btn-link ${!inDraftMode ? 'disabled' : ''}`} onClick={() => handleSelectAll(true)}>
                <span className="ms-1">Mark All Visible</span>
            </button>
            <button className={`btn btn-link ${!inDraftMode ? 'disabled' : ''}`} onClick={() => handleSelectAll(false)}>
                <span className="ms-1">Hide All</span>
            </button>
        </Styles>
    );
};

export default PipelineNodeColumnOrder;
